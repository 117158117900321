import { NewBookPublishedToast } from 'components/NotificationsAnatomy/Templates/Contract/NewBookPublished';
import { ReviewContractApprovedToast } from 'components/NotificationsAnatomy/Templates/Contract/ReviewContractApproved';
import { ReviewContractLegalRejectToast } from 'components/NotificationsAnatomy/Templates/Contract/ReviewContractLegalReject';
import { ReviewContractLegalSuccessToast } from 'components/NotificationsAnatomy/Templates/Contract/ReviewContractLegalSuccess';
import { ReviewContractRejectToast } from 'components/NotificationsAnatomy/Templates/Contract/ReviewContractReject';
import { ReviewContractStartToast } from 'components/NotificationsAnatomy/Templates/Contract/ReviewContractStart';
import { EmailVerificationToast } from 'components/NotificationsAnatomy/Templates/Info/EmailVerification';
import { LocalizationToast } from 'components/NotificationsAnatomy/Templates/Info/Localization';
import { RevenueSentToast } from 'components/NotificationsAnatomy/Templates/Revenue/RevenueSent';
import { RevenueUpdatedToast } from 'components/NotificationsAnatomy/Templates/Revenue/RevenueUpdated';
import { WelcomeLetterToast } from 'components/NotificationsAnatomy/Templates/Info/WelcomeLetter';
import { WithdrawRequestedToast } from 'components/NotificationsAnatomy/Templates/Revenue/WithdrawRequested';
import { DeleteApprovedToast } from 'components/NotificationsAnatomy/Templates/Story/DeleteApproved';
import { DeleteDraftApprovedToast } from 'components/NotificationsAnatomy/Templates/Story/DeleteDraftApproved';
import { DeleteRejectedToast } from 'components/NotificationsAnatomy/Templates/Story/DeleteRejected';
import { ReviewCanceledToast } from 'components/NotificationsAnatomy/Templates/Story/ReviewCanceled';
import { ReviewPublishedToast } from 'components/NotificationsAnatomy/Templates/Story/ReviewPublished';
import { ReviewRejectedToast } from 'components/NotificationsAnatomy/Templates/Story/ReviewRejected';
import { ReviewStartToast } from 'components/NotificationsAnatomy/Templates/Story/ReviewStart';
import { WaitingForReviewToast } from 'components/NotificationsAnatomy/Templates/Story/WaitingForReview';
import { FC } from 'react';
import { NOTIFICATION_TYPE } from 'utils/enums/notification-type.enum';
import { Notification } from 'utils/types/notifications';
import { NewContractRequestToast } from '../Templates/Admin/NewContractRequest';
import { CancelRequestToast } from '../Templates/Admin/CancelRequest';
import { BookUpdateRequestToast } from '../Templates/Admin/BookUpdateRequest';
import { NewBookReviewRequestToast } from '../Templates/Admin/NewBookReviewRequest';
import { BookDeleteRequestToast } from '../Templates/Admin/BookDeleteRequest';
import { UserWithdrawalRequestToast } from '../Templates/Admin/UserWithdrawalRequest';
import { PaymentDataRequestCreatedToast } from '../Templates/PaymentData/PaymentDataRequestCreated';
import { PaymentDataRequestCanceledToast } from '../Templates/PaymentData/PaymentDataRequestCanceled';
import { NewPaymentDataChangeRequestToast } from '../Templates/Admin/NewPaymentDataChangeRequest';
import { NewPaymentDataCancelRequestToast } from '../Templates/Admin/NewPaymentDataCancelRequest';
import { PaymentDataRequestApprovedToast } from '../Templates/PaymentData/PaymentDataRequestApproved';
import { PaymentDataRequestRejectedToast } from '../Templates/PaymentData/PaymentDataRequestRejected';
import { NewContractToast } from '../Templates/Admin/NewContract';
import { CustomToast } from '../Templates/Custom';
import { PenNameToast } from '../Templates/Info/PenName';
import { DashboardsUpdatesToast } from '../Templates/Info/DashboardsUpdates';
import { WritersContestToast } from '../Templates/Info/WritersContest';
import { ContestApplicationToast } from '../Templates/Admin/ContestApplication';
import { NewYear2025Toast } from '../Templates/Info/NewYear2025';
import { AdditionalAgreementAToast } from '../Templates/Contract/AdditionalAgreementA';
import { AdditionalAgreementBToast } from '../Templates/Contract/AdditionalAgreementB';

type Props = {
  notification: Notification;
  handleClose: (id: string) => void;
};

export const NotificationToast: FC<Props> = ({
  notification,
  handleClose,
}) => {
  const {
    type,
    book,
    notification_id,
    created_by,
    title,
    text,
  } = notification;
  switch (type) {
    // #region admin notifications
    case NOTIFICATION_TYPE.CONTEST_APPLICATION:
      return (
        <ContestApplicationToast
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.NEW_CONTRACT:
      return (
        <NewContractToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.NEW_PAYMENT_DATA_REQUEST_CANCELED:
      return (
        <NewPaymentDataCancelRequestToast
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.NEW_PAYMENT_DATA_CHANGE_REQUEST:
      return (
        <NewPaymentDataChangeRequestToast
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.USER_WITHDRAWAL_REQUEST:
      return (
        <UserWithdrawalRequestToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.BOOK_DELETE_REQUEST:
      return (
        <BookDeleteRequestToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.NEW_BOOK_REVIEW_REQUEST:
      return (
        <NewBookReviewRequestToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.BOOK_UPDATE_REQUEST:
      return (
        <BookUpdateRequestToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.NEW_CONTRACT_REQUEST:
      return (
        <NewContractRequestToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

      case NOTIFICATION_TYPE.CANCELED_REQUEST:
        return (
          <CancelRequestToast
            title={book?.title ?? ''}
            id={notification_id}
            handleClose={handleClose}
            createdBy={created_by || ''}
          />
        );
    // #endregion

    // #region user notifications
    case NOTIFICATION_TYPE.ADDITIONAL_AGREEMENT_A:
      return (
        <AdditionalAgreementAToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

      case NOTIFICATION_TYPE.ADDITIONAL_AGREEMENT_B:
        return (
          <AdditionalAgreementBToast
            id={notification_id}
            handleClose={handleClose}
          />
        );

    case NOTIFICATION_TYPE.NEW_YEAR_2025:
      return (
        <NewYear2025Toast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.WRITERS_CONTEST:
      return (
        <WritersContestToast
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.DASHBOARD_UPDATES:
      return (
        <DashboardsUpdatesToast
          id={notification_id}
          handleClose={handleClose}
          createdBy={created_by || ''}
        />
      );

    case NOTIFICATION_TYPE.PEN_NAME:
      return (
        <PenNameToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.CUSTOM:
      return (
        <CustomToast
          id={notification_id}
          title={title || ''}
          text={text || ''}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.PAYMENT_DATA_REQUEST_REJECTED:
      return (
        <PaymentDataRequestRejectedToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.PAYMENT_DATA_REQUEST_APPROVED:
      return (
        <PaymentDataRequestApprovedToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.PAYMENT_DATA_REQUEST_CANCELED:
      return (
        <PaymentDataRequestCanceledToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.PAYMENT_DATA_REQUEST_CREATED:
      return (
        <PaymentDataRequestCreatedToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVENUE_UPDATE:
      return (
        <RevenueUpdatedToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVENUE_SENT:
      return (
        <RevenueSentToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.WITHDRAW_REQUEST:
      return (
        <WithdrawRequestedToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.DELETE_DRAFT:
      return (
        <DeleteDraftApprovedToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.DELETE_APPROVED:
      return (
        <DeleteApprovedToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.DELETE_REJECT:
      return (
        <DeleteRejectedToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_PUBLISHED:
      return (
        <ReviewPublishedToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_REJECTED:
      return (
        <ReviewRejectedToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.NEW_BOOK_PUBLISHED:
      return (
        <NewBookPublishedToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_CONTRACT_APPROVED:
      return (
        <ReviewContractApprovedToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_CONTRACT_REJECT:
      return (
        <ReviewContractRejectToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_CONTRACT_LEGAL_SUCCESS:
      return (
        <ReviewContractLegalSuccessToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_CONTRACT_LEGAL_REJECT:
      return (
        <ReviewContractLegalRejectToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_CONTRACT_STARTED:
      return (
        <ReviewContractStartToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );
    
    case NOTIFICATION_TYPE.REVIEW_STARTED:
      return (
        <ReviewStartToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.REVIEW_CANCELED:
      return (
        <ReviewCanceledToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.WAITING_FOR_REVIEW:
      return (
        <WaitingForReviewToast
          title={book?.title ?? ''}
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.EMAIL_VERIFICATION:
      return (
        <EmailVerificationToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.WELCOME:
      return (
        <WelcomeLetterToast
          id={notification_id}
          handleClose={handleClose}
        />
      );

    case NOTIFICATION_TYPE.LOCALIZATION:
      return (
        <LocalizationToast
          id={notification_id}
          handleClose={handleClose}
        />
      );
    // #endregion

    default:
      return <></>;
  }
};
