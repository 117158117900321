import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { UserContext } from 'context/UserContext';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { BaseFullProps, BaseShortProps, NotifToastProps } from '../../../../../utils/types/notifications/templates';
import { OneColorIcon } from '../../Story/Icon';
import { useNavigate } from 'react-router-dom';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { Body } from '../../UI/Toast/Body';
import { EMAILS } from 'constants/email/Email';
import { EmailTemplates } from 'constants/email/EmailTemplates';
import { getFromLocalStorage } from 'utils/helpers/local-storage/localStorage';

export const AdditionalAgreementBToast: FC<NotifToastProps> = ({
  id,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={colors['light-rose-100']}
          img="/images/create-story/rupor.png"
        />
      </Box>

      <Body
        text={t('We\'re enhancing My Passion to reach more readers! To continue writing with us, please review and sign the additional agreement to your license agreement')}
        title={t('Important Update')}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const AdditionalAgreementBShort: FC<BaseShortProps> = ({
  id,
  isSelected,
  user_readed,
  createdAt,
  toggleSelect,
  handleOpenNotification,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
      bg={bg}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={colors['light-rose-100']}
          img="/images/create-story/rupor.png"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Important Update')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          {t('We\'re enhancing My Passion to reach more readers! To continue writing with us, please review and sign the additional agreement to your license agreement.')}
        </Text>
      </Flex>
    </Flex>
  );
};

export const AdditionalAgreementBFull: FC<BaseFullProps> = ({
  createdAt,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);

  const user_id = getFromLocalStorage('user_id');

  const handleContinue = () => {
    navigate('/writer/additional-agreement');
  };

  const handleCreateEmail = () => {
    const link = document.createElement('a');
    link.href = EmailTemplates.CONTRACT_SUPPORT(user_id);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={colors['light-rose-100']}
              img="/images/create-story/rupor.png"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {t('Passion Team')}
            </Text>
            {' <support@passionapp.co>'}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="80px"
        h="80px"
        src="/images/contract/marker.png"
        loading="eager"
        alt="in review"
      />

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="36px"
        letterSpacing={0}
        fontWeight={400}
      >
        {t(`Hi, ${user?.first_name}!`)}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        letterSpacing={0}
        maxW="600px"
        textAlign="center"
        mb="20px"
      >
        {t('We\'re enhancing My Passion to reach more readers! To continue writing with us, please review and sign the additional agreement to your license agreement. ✍️ Reviewing and signing the updated agreement is quick and easy.')}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        letterSpacing={0}
        maxW="600px"
        textAlign="center"
        mb="20px"
      >
        {t('✍️ Tap "Continue" to proceed and continue contributing to our community.🚀 ')}
      </Text>

      <Button
        variant="defaultButton"
        onClick={handleContinue}
      >
        {t('Continue')}
      </Button>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        textAlign="center"
      >
        {t('If you have any questions or require further details, feel free to reach out to our support team')}
        <br />
        <Text
          as="span"
          variant="primary-violet-100"
          size="poppins20normal"
          lineHeight="32px"
          textDecoration="underline"
          cursor="pointer"
          onClick={handleCreateEmail}
        >
          {EMAILS.support}
        </Text>
      </Text>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};
